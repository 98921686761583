import Head from "next/head";
import React from "react";
// import ConnectWalletModel from "../../Components/ConnectWalletModel";
// import PurchaseNftModel from "../../Components/PurchaseNftModel";
// import ShowOnIPFS from "../../Components/ShowOnIPFS";
// import TxModel from "../../Components/TransactionModel";
// import WrongNetworkModel from "../../Components/WrongNetworkModel";
import NFT_Constants from "../../Configs/Constants";
import UseAuth from "../../Contexts/Auth/useAuth";
import UseUser from "../../Contexts/User/useUser";
import UseUtil from "../../Contexts/Util/useUtil";
import useDarkOverlay from "../../Hooks/DarkOverlay";
// import Footer from "./Footer";
// import Header from "./Header";
// import Main from "./Main";
import dynamic from 'next/dynamic'
const Footer = dynamic(() => import('./Footer'))
const Header = dynamic(() => import('./Header'))
const Main = dynamic(() => import('./Main'))
const WrongNetworkModel = dynamic(() => import('../../Components/WrongNetworkModel'))
const TxModel = dynamic(() => import('../../Components/TransactionModel'))
const ShowOnIPFS = dynamic(() => import('../../Components/ShowOnIPFS'))
const PurchaseNftModel = dynamic(() => import('../../Components/PurchaseNftModel'))
const ConnectWalletModel = dynamic(() => import('../../Components/ConnectWalletModel'))
const Layout = ({ title, ...props }) => {
  const {
    utilState,
    showConnectWalletPopup,
    hideConnectWalletPopup,
    hideTransactionPopup,
    hidePurchaseNftPopup,
    hideWrongNetworkPopup,
    hideOnIPFSPopup
  } = UseUtil();
  const { authState, loginUser, disconnetWallet, setAuthCookies } = UseAuth();
  const { userState } = UseUser();

  return (
    <>
      {/* <SeoDtataManagment> */}
      <Head>
        <title>{title || NFT_Constants.Title}</title>
        {/* <meta name="viewport" content="initial-scale=1.0, width=device-width" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta name="title" content="Create, Buy and Sell NFTs and Crypto Collectibles - NFTON " />
        <meta name="description" content="NFTON is a leading NFT marketplace to create, buy and sell all kinds of digital art, music and crypto-collectibles via secure Ethereum based blockchain solutions." />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_WEB_URL}/`} />
        {/* {
          
            <>
              <script async src="https://www.googletagmanager.com/gtag/js?id=G-L0XPZVT490" />
              <script>
                {ga()}
              </script>
            </>
        } */}
      </Head>
      <Header
        authData={authState}
        disconnetWallet={disconnetWallet}
        showWalletEvent={showConnectWalletPopup}
        userData={userState}
      />
      <Main>
        {useDarkOverlay(utilState.isDarkLayoutOn ?? false)}
        <ConnectWalletModel
        authData={authState}
          visible={utilState.connectWalletPopup}
          onHide={hideConnectWalletPopup}
          setAuthCookies={setAuthCookies}
          loginEvent={(payload) => loginUser(payload)}
        />
        <WrongNetworkModel
          visible={utilState.wrongNetworkPopup}
          onHide={hideWrongNetworkPopup}
          setAuthCookies={setAuthCookies}
          loginEvent={(payload) => loginUser(payload)}
        />
        <TxModel
          visible={utilState.transactionProgress}
          onHide={hideTransactionPopup}
          setAuthCookies={setAuthCookies}
          loginEvent={(payload) => loginUser(payload)}
          tx_title={utilState.transactionText}
          title1={utilState.extra1}
          title2={utilState.extra2}
        />
        <PurchaseNftModel
          visible={utilState.purchaseNftPopup}
          onHide={hidePurchaseNftPopup}
          setAuthCookies={setAuthCookies}
          loginEvent={(payload) => loginUser(payload)}
          purchaseData={utilState.purchaseData}
        />
        <ShowOnIPFS
          visible={utilState.ipfsPopup}
          onHide={hideOnIPFSPopup}
          setAuthCookies={setAuthCookies}
          loginEvent={(payload) => loginUser(payload)}
          ipfsData={utilState.ipfsData}
        />

        {props.children}
      </Main>
      <Footer />
      {/* </SeoDtataManagment> */}
    </>
  );
};

export default Layout;
